<template>
  <div>
    <div class="row">
      <div class="col-8">
        <div class="sqxwIi">
          <div class="_3cPNXP">
            <div class="V-sVj2">
              <div class="jNp+ZB ktatB-">
                <h2 class="_6HCfS6">
                  สั่งซื้อสินค้าแล้ว
                </h2>
              </div>
              <div class="jNp+ZB _04sLFc" />
              <div class="jNp+ZB">
                ราคาต่อหน่วย
              </div>
              <div class="jNp+ZB">
                จำนวน
              </div>
              <div class="jNp+ZB LBqTli">
                รายการย่อย
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="(shop, shopin) in order"
          :key="shopin"
        >
          <div class="Z7qspM mb-1">
            <div class="vYrpLx">
              <div>
                <div class="lAXOsx _2bC6t1">
                  <img
                    :src="`@/assets/images/anan-img/svg/${shop.provider}.svg`"
                    alt="icon"
                    width="20"
                  >
                </div>
              </div>
              <h3 class="YSl9dN mb-0">
                {{ shop.storename }}
              </h3>

              <div class="_75ySYI">
                {{ shop.provider }}
              </div>
            </div>
            <div class="KxX-H6">
              <div
                v-for="(product, productin) in shop.products"
                :key="productin"
                class="_2OGC7L xBI6Zm"
              >
                <div class="h3ONzh EOqcX3">
                  <img
                    class="rTOisL"
                    alt="product image"
                    :src="product.color_img ? product.color_img : product.size_img ? product.size_img : product.picture"
                    width="40"
                    height="40"
                  >
                  <span class="oEI3Ln">
                    <span class="gHbVhc">{{ product.name }}</span>
                    <div class="">
                      <p
                        v-if="product.color_title"
                        class="mb-0 small text-muted mt--5"
                      >สี : {{ product.color_title }}</p>
                      <p
                        v-if="product.size_title"
                        class="mb-0 small text-muted mt--10"
                      >ขนาด : {{ product.size_title }}</p>
                    </div>
                  </span>
                </div>
                <div class="h3ONzh Le31ox" />
                <div class="h3ONzh">
                  ¥ {{ Commas(product.price) }}
                </div>
                <div class="h3ONzh">
                  {{ Commas(product.quantity) }}
                </div>
                <div class="h3ONzh fHRPUO">
                  ¥ {{ Commas(product.price * product.quantity) }}
                </div>
              </div>
            </div>
            <div class="cETXAA">
              <div class="N5Svcv">
                <div class="IsPQKt">
                  <div class="U7Xuk4">
                    <b-form-checkbox
                      v-model="shop.packbox"
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      @input="ChangeHeadShop(shop)"
                    >
                      ตีลังไม้
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-model="shop.checkproduct"
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      @input="ChangeHeadShop(shop)"
                    >
                      เช็คสินค้า
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="uw1QJu KL2Fv5">
                <div class="za7qoR">
                  <div class="D2AHAU">
                    <div class="hcC4-3">
                      <div>ยอดสั่งซื้อทั้งหมด ({{ shop.products.length }} ชิ้น):</div>
                    </div>
                  </div>
                  <div class="zjjc32">
                    <div>
                      <div>
                        <div class="_9F3E9v">
                          ฿ {{ Commas(shop.products.reduce((acc, ele) => acc + (ele.price*ele.quantity), 0) * Rate) }}
                        </div>
                        <div style="display: contents" />
                      </div>
                    </div>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="-p7ULT">
          <div class="vtrWey" />
          <div class="_8jJlG8">
            <h5 class="text-primary">
              <i class="fal fa-shipping-fast" /> {{ $t('key-112') }}
            </h5>

            <b-form-radio
              v-model="delivery_type"
              name="some-radios"
              value="1"
              class="mb-1 mt-1"
            >
              {{ $t('byTruck') }} (ระยะเวลา 5-7 วัน)
            </b-form-radio>

            <b-form-radio
              v-model="delivery_type"
              name="some-radios"
              value="2"
            >
              {{ $t('byship') }} (ระยะเวลา 15-25 วัน)
            </b-form-radio>

            <div class="mt-1">
              <label for="keyword">{{ $t('mnemonic') }}</label>
              <b-form-textarea
                id="keyword"
                v-model="note"
                class="form-control"
                rows="2"
              />
            </div>

            <div class="text-right mt-1">
              {{ $t('key-121') }} ({{ Commas(order.reduce((acc, ele)=> acc + ele.products.reduce((acc2, ele2) => acc2 + ele2.quantity, 0), 0)) }} รายการ)
            </div>

            <div
              class="text-right"
              style="margin-top: 8px;"
            >
              <span class="cart-page-footer-summary__subtotal-text">¥ {{ Commas(order.reduce((acc, ele)=> acc + ele.products.reduce((acc2, ele2) => acc2 + (ele2.price*ele2.quantity), 0), 0)) }} x {{ Rate }} =</span><span class="cart-page-footer-summary__subtotal-amount notranslate">฿ {{ Commas(order.reduce((acc, ele)=> acc + ele.products.reduce((acc2, ele2) => acc2 + (ele2.price*ele2.quantity), 0), 0) * Rate) }}</span>
            </div>

            <div
              class="cart-page-footer__second-summary text-right"
              style="margin-top: 8px;"
            >
              {{ $t('exRate') }} 1 ¥ = {{ Rate }} {{ $t('baht') }}
            </div>

            <button
              class="btn btn-gradient-primary w-100 mt-1"
              @click="Submit()"
            >
              {{ $t('key-120') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormRadio, BFormTextarea, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'CheckoutView',
  components: {
    BFormRadio,
    BFormTextarea,
    BFormCheckbox,
  },
  data() {
    return {
      keyword: '',
      transport: 'car',
      options: [
        { text: 'First radio', value: 'first' },
        { text: 'Second radio', value: 'second' },
      ],
      order: [],
      orderId: [],
      Rate: 0,
      delivery_type: 1,
      note: '',
    }
  },
  computed: {},
  mounted() {
    this.order = JSON.parse(localStorage.getItem('Order'))
    this.orderId = JSON.parse(localStorage.getItem('OrderId'))
    this.GetRate()
    // console.log('order')
    // console.log(this.order)
    // console.log(this.orderId)
  },
  methods: {
    Submit() {
      const obj = {
        order: this.orderId,
        delivery_type: this.delivery_type,
        note: this.note,
      }
      this.$swal({
        title: 'ยืนยันที่จะสั่งสินค้าหรือไม่ ?',
        // text: `หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .post('/order/store', obj)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              localStorage.setItem('Order', '{}')
              localStorage.setItem('OrderId', '{}')
              this.$router.push({ name: 'order-list' })
            })
            .catch(error => {
              console.log(error)
              this.Alert(false, 'เกิดข้อผิดพลาด', 'ไม่สามารถสั่งสินค้าได้')
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    async ChangeHeadShop(Shop) {
      try {
        const obj = {
          // eslint-disable-next-line no-underscore-dangle
          shops_id: Shop.shops_id,
          packbox: Shop.packbox,
          checkproduct: Shop.checkproduct,
        }
        await this.$http.post('/cart/CheckAndPack', obj)
        localStorage.setItem('Order', JSON.stringify(this.order))
      } catch (error) {
        console.log(error)
      }
    },
    async GetRate() {
      try {
        const { data: res } = await this.$http.get('/product/rate')
        if (res.status) {
          this.Rate = res.data.cSelling
        }
      } catch (error) {
        console.log(error)
      }
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
